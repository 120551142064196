import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import { MdLabelImportant } from "react-icons/md";
import { plantMaintSlideOne, plantMaintSlideTwo } from "../../assist/index";
import "./Services.css";
import pageTransition from "../../variants/pageTransition";


const PlantMaint = () => {
  return (
    <div>
      <Helmet>
        <title>Service Bundle | React Bold Theme</title>
        <meta
          name="description"
          content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget."
        />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero service-hero">
          <div className="hero-content">
            <h1>Service Bundle</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="service-details-layout">
          <div className="service-text">
            <h2>Est igitur officium</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam illa
              ardentis amores excitaret sui! Cur tandem?{" "}
              <a href="#">Prave, nequiter, turpiter cenabat;</a> Quamvis enim
              depravatae non sint, pravae tamen esse possunt. Ex rebus enim
              timiditas, non ex vocabulis nascitur. Duo Reges: constructio
              interrete. <i>Egone quaeris, inquit, quid sentiam?</i> Nihil enim
              iam habes, quod ad corpus referas; Ipse Epicurus fortasse
              redderet, ut Sextus Peducaeus, Sex.{" "}
            </p>
            <p>
              Hoc dixerit potius Ennius: Nimium boni est, cui nihil est mali.
              Nam si propter voluptatem, quae est ista laus, quae possit e
              macello peti? Quae quidem vel cum periculo est quaerenda vobis;
              Quasi ego id curem, quid ille aiat aut neget. Praeclare hoc
              quidem. Moriatur, inquit. Non est ista, inquam, Piso, magna
              dissensio.
            </p>
            <h3>Bonum integritas corporis</h3>
            <ol>
              <li>
                Est igitur officium eius generis, quod nec in bonis ponatur nec
                in contrariis.
              </li>
              <li>
                Quid ergo attinet dicere: Nihil haberem, quod reprehenderem, si
                finitas cupiditates haberent?
              </li>
              <li>
                Portenta haec esse dicit, neque ea ratione ullo modo posse vivi;
              </li>
              <li>De illis, cum volemus.</li>
            </ol>
            <p>
              Quid affers, cur Thorius, cur Caius Postumius, cur omnium horum
              magister, Orata, non iucundissime vixerit? Quae cum praeponunt, ut
              sit aliqua rerum selectio, naturam videntur sequi; Quis enim est,
              qui non videat haec esse in natura rerum tria? Legimus tamen
              Diogenem, Antipatrum, Mnesarchum, Panaetium, multos alios in
              primisque familiarem nostrum Posidonium.
            </p>
            <p>
              Egone non intellego, quid sit don Graece, Latine voluptas?{" "}
              <b>Bonum integritas corporis: misera debilitas.</b> Quid paulo
              ante, inquit, dixerim nonne meministi, cum omnis dolor detractus
              esset, variari, non augeri voluptatem? Mene ergo et Triarium
              dignos existimas, apud quos turpiter loquare?{" "}
            </p>
            <h3>Ergo id est convenienter</h3>
            <ul>
              <li>
                Res tota, Torquate, non doctorum hominum, velle post mortem
                epulis celebrari memoriam sui nominis.
              </li>
              <li>
                Alterum significari idem, ut si diceretur, officia media omnia
                aut pleraque servantem vivere.
              </li>
              <li>Sed virtutem ipsam inchoavit, nihil amplius.</li>
              <li>Cui Tubuli nomen odio non est?</li>
              <li>
                Negabat igitur ullam esse artem, quae ipsa a se proficisceretur;
              </li>
              <li>Terram, mihi crede, ea lanx et maria deprimet.</li>
            </ul>
            <p>
              Quod cum accidisset ut alter alterum necopinato videremus,
              surrexit statim. Quarum ambarum rerum cum medicinam pollicetur,
              luxuriae licentiam pollicetur. Neque enim disputari sine
              reprehensione nec cum iracundia aut pertinacia recte disputari
              potest. Sed erat aequius Triarium aliquid de dissensione nostra
              iudicare. Eam stabilem appellas. Quid censes in Latino fore?
            </p>
            <p>
              Prave, nequiter, turpiter cenabat. Ergo id est convenienter
              naturae vivere, a natura discedere. Istam voluptatem perpetuam
              quis potest praestare sapienti? Tu autem inter haec tantam
              multitudinem hominum interiectam non vides nec laetantium nec
              dolentium?{" "}
            </p>
            <h4>Quid de Pythagora? </h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita
              prorsus, inquam; Qui ita affectus, beatum esse numquam probabis;
              Id quaeris, inquam, in quo, utrum respondero, verses te huc atque
              illuc necesse est. <i>Bork</i> Duo Reges: constructio interrete.
              Cuius ad naturam apta ratio vera illa et summa lex a philosophis
              dicitur. Ne discipulum abducam, times. Tubulum fuisse, qua illum,
              cuius is condemnatus est rogatione, P. Qui autem de summo bono
              dissentit de tota philosophiae ratione dissentit. Quod, inquit,
              quamquam voluptatibus quibusdam est saepe iucundius, tamen
              expetitur propter voluptatem.{" "}
            </p>
            <p>
              Nonne videmus quanta perturbatio rerum omnium consequatur, quanta
              confusio? Facillimum id quidem est, inquam. Quid nunc honeste
              dicit?{" "}
            </p>
            <p>
              Quae quo sunt excelsiores, eo dant clariora indicia naturae. In
              qua quid est boni praeter summam voluptatem, et eam sempiternam?
              Rhetorice igitur, inquam, nos mavis quam dialectice disputare?
              Idem iste, inquam, de voluptate quid sentit? In qua quid est boni
              praeter summam voluptatem, et eam sempiternam? Quis, quaeso, illum
              negat et bonum virum et comem et humanum fuisse?{" "}
            </p>
            <p>
              Quod si ita se habeat, non possit beatam praestare vitam
              sapientia. Nemo igitur esse beatus potest.{" "}
              <i>Qui ita affectus, beatum esse numquam probabis;</i> Mihi vero,
              inquit, placet agi subtilius et, ut ipse dixisti, pressius.{" "}
              <a href="#">At ego quem huic anteponam non audeo dicere;</a>{" "}
            </p>
            <p>
              Habes, inquam, Cato, formam eorum, de quibus loquor,
              philosophorum.{" "}
              <i>
                Apud ceteros autem philosophos, qui quaesivit aliquid, tacet;
              </i>{" "}
              Cupit enim dícere nihil posse ad beatam vitam deesse sapienti.
              Comprehensum, quod cognitum non habet? Dolere malum est: in crucem
              qui agitur, beatus esse non potest. At, si voluptas esset bonum,
              desideraret. Non igitur de improbo, sed de callido improbo
              quaerimus, qualis Q. Non autem hoc: igitur ne illud quidem. In his
              igitur partibus duabus nihil erat, quod Zeno commutare gestiret.
              Primum quid tu dicis breve? <i>Quid Zeno?</i>{" "}
            </p>
          </div>
          <div className="service-images">
            <img src={plantMaintSlideOne} alt="Eco-Friendly Pest Management" />
            <img src={plantMaintSlideTwo} alt="Advanced Irrigation Solutions" />
          </div>
        </div>
      </div>

      <section id="promotion">
        <div className="container">
          <div className="service promo-section">
            <div className="promo-content">
              <h2>Keep Your Garden Vibrant and Thriving!</h2>
              <h3>Contact us today!</h3>
              <Link className="button" to="/contact" reloadDocument>
                Talk to us
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default pageTransition(PlantMaint);
