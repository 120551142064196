import React from "react";
import { Helmet } from 'react-helmet-async';
import AnnouncementBar from "../../components/shared/AnnouncementBar";
import "./IndividualBlog.css";
import pageTransition from "../../variants/pageTransition";
import BlogSidebar from "../shared/BlogSidebar";
import { Link } from "react-router-dom";

const IndividualBlog = () => {
  return (
    <div>
      <Helmet>
        <title>Blog Post | React Bold Theme</title>
        <meta
          name="description"
          content="Aenean pulvinar congue nulla, sed bibendum justo hendrerit eu. Cras sit amet dui porta, gravida augue eget."
        />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="hero individual-blog-hero">
          <div className="hero-content">
            <h1>Aliter enim explicari quod quaeritur</h1>
          </div>
        </div>
        <div className="container">
          <div className="content-wrapper">
            <div className="individual-blog-content">
              <article>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et
                  quidem iure fortasse, sed tamen non gravissimum est
                  testimonium multitudinis. Estne, quaeso, inquam, sitienti in
                  bibendo voluptas? Quod idem cum vestri faciant, non satis
                  magnam tribuunt inventoribus gratiam.{" "}
                </p>
                <p>
                  Qualis ista philosophia est, quae non interitum afferat
                  pravitatis, sed sit contenta mediocritate vitiorum? Sed quot
                  homines, tot sententiae; Bona autem corporis huic sunt, quod
                  posterius posui, similiora. Qua igitur re ab deo vincitur, si
                  aeternitate non vincitur? Et ille ridens: Video, inquit, quid
                  agas; Philosophi autem in suis lectulis plerumque moriuntur.{" "}
                </p>
                <ol>
                  <li>
                    In qua quid est boni praeter summam voluptatem, et eam
                    sempiternam?
                  </li>
                  <li>
                    Is ita vivebat, ut nulla tam exquisita posset inveniri
                    voluptas, qua non abundaret.
                  </li>
                  <li>
                    Num igitur eum postea censes anxio animo aut sollicito
                    fuisse?
                  </li>
                  <li>Quasi ego id curem, quid ille aiat aut neget.</li>
                  <li>
                    Commoda autem et incommoda in eo genere sunt, quae
                    praeposita et reiecta diximus;
                  </li>
                </ol>
                <h2>Pauca mutat vel plura sane</h2>
                <p>
                  Non autem hoc: igitur ne illud quidem. Quam ob rem tandem,
                  inquit, non satisfacit? Aut unde est hoc contritum vetustate
                  proverbium: quicum in tenebris? Haec quo modo conveniant, non
                  sane intellego. Hoc unum Aristo tenuit: praeter vitia atque
                  virtutes negavit rem esse ullam aut fugiendam aut expetendam.
                  Hoc ille tuus non vult omnibusque ex rebus voluptatem quasi
                  mercedem exigit. Nam illud vehementer repugnat, eundem beatum
                  esse et multis malis oppressum. Certe nihil nisi quod possit
                  ipsum propter se iure laudari.{" "}
                </p>
                <p>
                  Potius inflammat, ut coercendi magis quam dedocendi esse
                  videantur.{" "}
                  <a href="#">Tuo vero id quidem, inquam, arbitratu.</a> Tum
                  Quintus: Est plane, Piso, ut dicis, inquit. Sed plane dicit
                  quod intellegit.{" "}
                </p>
                <p>
                  Sint modo partes vitae beatae. Nam ante Aristippus, et ille
                  melius. In schola desinis. Ut proverbia non nulla veriora sint
                  quam vestra dogmata. Roges enim Aristonem, bonane ei videantur
                  haec: vacuitas doloris, divitiae, valitudo; Ut proverbia non
                  nulla veriora sint quam vestra dogmata. Quodsi ipsam
                  honestatem undique pertectam atque absolutam. Quae cum
                  magnifice primo dici viderentur, considerata minus
                  probabantur. Sed quid minus probandum quam esse aliquem beatum
                  nec satis beatum? Non enim, si omnia non sequebatur, idcirco
                  non erat ortus illinc. Stuprata per vim Lucretia a regis filio
                  testata civis se ipsa interemit. Cum autem in quo sapienter
                  dicimus, id a primo rectissime dicitur.{" "}
                </p>
                <h2>Bonum integritas corporis: misera debilitas.</h2>
                <p>
                  Ne discipulum abducam, times. Nunc de hominis summo bono
                  quaeritur; Duo Reges: constructio interrete. Re mihi non aeque
                  satisfacit, et quidem locis pluribus. Esse enim, nisi eris,
                  non potes.{" "}
                </p>
                <p>
                  Egone non intellego, quid sit don Graece, Latine voluptas?
                  Quod autem magnum dolorem brevem, longinquum levem esse
                  dicitis, id non intellego quale sit. Sed quia studebat laudi
                  et dignitati, multum in virtute processerat. Nam aliquando
                  posse recte fieri dicunt nulla expectata nec quaesita
                  voluptate. Nam quid possumus facere melius? Quid enim tanto
                  opus est instrumento in optimis artibus comparandis? Bonum
                  negas esse divitias, praeposìtum esse dicis? Nam, ut sint illa
                  vendibiliora, haec uberiora certe sunt.{" "}
                </p>
                <ul>
                  <li>
                    Deinceps videndum est, quoniam satis apertum est sibi
                    quemque natura esse carum, quae sit hominis natura.
                  </li>
                  <li>
                    Quae cum magnifice primo dici viderentur, considerata minus
                    probabantur.
                  </li>
                  <li>
                    Non enim solum Torquatus dixit quid sentiret, sed etiam cur.
                  </li>
                  <li>
                    Dolere malum est: in crucem qui agitur, beatus esse non
                    potest. Si id dicis, vicimus
                  </li>
                </ul>
                <p>
                  Ut in geometria, prima si dederis, danda sunt omnia. Itaque
                  primos congressus copulationesque et consuetudinum
                  instituendarum voluntates fieri propter voluptatem; Nam memini
                  etiam quae nolo, oblivisci non possum quae volo. Est enim
                  effectrix multarum et magnarum voluptatum.{" "}
                </p>
                <p>
                  Commoda autem et incommoda in eo genere sunt, quae praeposita
                  et reiecta diximus; Ita fit cum gravior, tum etiam splendidior
                  oratio. Non potes, nisi retexueris illa. Ex quo, id quod omnes
                  expetunt, beate vivendi ratio inveniri et comparari potest.
                  Tanta vis admonitionis inest in locis; Tum Piso: Quoniam
                  igitur aliquid omnes, quid Lucius noster?
                </p>
              </article>
            </div>
            <BlogSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default pageTransition(IndividualBlog);
